<template>
  <div>
    <Spinner v-if="!cmsContentPending" class="mt-5 mb-5" size="medium" line-fg-color="#1f9979" />
    <!-- privacy section -->
    <section v-else class="conditions">
      <b-container>
        <b-row>
          <b-col cols="12" lg="7">
            <h1 v-html="$prismic.asHtml(this.privacy_title)" class="conditions__title"></h1>
            <p v-html="$prismic.asHtml(this.privacy_info)" class="conditions__info"></p>
          </b-col>
          <b-col cols="12" lg="5">
            <img v-lazy="this.privacy_image.url" class="conditions__image img-fluid" :alt="this.privacy_image.alt" />
          </b-col>
        </b-row>
      </b-container>
      <b-container>
        <b-row>
          <b-col cols="12" lg="7">
            <div class="conditions__content" v-html="$prismic.asHtml(this.privacy_content)"></div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <!-- aside section -->
    <AsideComponent />
  </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
export default {
  name: 'PrivacyPage',
  components: {
    Spinner,
    AsideComponent: () => import('@/components/AsideComponent/AsideComponent.vue')
  },
  data: () => {
    return {
      cmsContentPending: false,
      privacy_title: null,
      privacy_info: null,
      privacy_content: null,
      privacy_image: null
    }
  },
  methods: {
    async getPrivacyPageContent () {
      await this.$prismic.client.getSingle('privacy')
        .then((document) => {
          this.privacy_title = document.data.privacy_title
          this.privacy_info = document.data.privacy_info
          this.privacy_content = document.data.privacy_content
          this.privacy_image = document.data.privacy_image
          // meta title
          window.document.querySelector('head title').textContent = this.$prismic.richTextAsPlain(document.data.meta_title)
          this.cmsContentPending = true
        })
    }
  },
  created () {
    this.getPrivacyPageContent()
  }
}
</script>

<style lang="scss" scoped>
  @import "@/styles/_conditions.scss";
</style>
